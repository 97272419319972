@import url('https://fonts.googleapis.com/css?family=Amiri&display=swap');

a {
	color: rgb(165 69 0);
}

h1, h2, h3, h4 {
	font-family: Amiri, sans-serif;
}

button.book {
	background-color: rgba(0, 0, 0, 0.8);
	border: solid 2px #fff;
	color: white;
	cursor: pointer;
	font-size: 1em;
	line-height: 38px;
	padding: 10px 28px;
	text-transform: uppercase;
}

.logo {
	filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.8));
	max-width: 100%;
	stroke: rgb(255 230 192 / 84%);
	stroke-width: 1px;
}

.hero .book {
	margin-top: 6em;
}

.book-modal-open {
	overflow: hidden;
}

.content label {
	color: rgb(109, 114, 119);
	font-size: 0.9em;
	font-style: italic;
}

.navbar-menu {
	font-family: Amiri, sans-serif;
	padding-left: 2em;
	text-transform: uppercase;
}

.hero h1 > span {
	display: none;
}

.hero h2 {
	color: #fff;
	font-size: 1.5em;
	font-weight: bold;;
	text-shadow: 0 1px 2px rgb(0,0,0), 0 1px 7px rgba(0,0,0,0.5);
}

.notification {
	background-color: rgba(255,255,255,0.5);
	margin-bottom: 1em;
}

.pic {
	box-shadow: 0px 4px 8px rgba(0,0,0,.5);
}

.map {
	position: relative;
}

.map::after {
	bottom: 10%;
	color: #666c84;
	content: "Chaska, MN";
	font-family: Amiri, sans-serif;
	font-size: 1em;
	font-weight: bold;
	left: 0;
	position: absolute;
	right: 0;
	text-align: center;
	text-shadow: 0 1px 2px #fff;
	text-transform: uppercase;
	z-index: 1;
}

#book-modal .modal-card-body {
	display: flex;
	flex-direction: column;
	height: 80vh;
	overflow: hidden;
	padding: 0;
}

.book-banner > h4 {
	font-size: 1.2em;
	font-weight: bold;
}

.book-banner {
	background: rgb(255, 211, 116);
	border: solid 1px rgb(230, 179, 69);
	border-radius: 2px;
	margin: 10px 20px;
	padding: 10px 20px;
}

#book-frame {
	height: 100%;
	width: 100%;
}

#about p {
	margin-bottom: 0.5rem;
}

.bg {
	background-attachment: fixed;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
	.bg {
		background-attachment: scroll;
	}
}

#home {
	background-image: url(img/chair.jpg);
}

.bg-hair1 {
	background-image: url(img/hair2.jpg);
	height: 300px;
}

/* .bg-hair2 {
	background-image: url(img/doorway.jpg);
	height: 800px;
} */

.bg-scissors {
	background-image: url(img/license.jpg);
	padding: 8em 0;
}

.bg-scissors h2 {
	color: #fff;
	text-shadow: 0 1px 2px rgb(0,0,0);
}
