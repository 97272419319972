@import "https://fonts.googleapis.com/css?family=Amiri&display=swap";
a {
  color: #a54500;
}

h1, h2, h3, h4 {
  font-family: Amiri, sans-serif;
}

button.book {
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  background-color: #000c;
  border: 2px solid #fff;
  padding: 10px 28px;
  font-size: 1em;
  line-height: 38px;
}

.logo {
  filter: drop-shadow(0 0 3px #000c);
  stroke: #ffe6c0d6;
  stroke-width: 1px;
  max-width: 100%;
}

.hero .book {
  margin-top: 6em;
}

.book-modal-open {
  overflow: hidden;
}

.content label {
  color: #6d7277;
  font-size: .9em;
  font-style: italic;
}

.navbar-menu {
  text-transform: uppercase;
  padding-left: 2em;
  font-family: Amiri, sans-serif;
}

.hero h1 > span {
  display: none;
}

.hero h2 {
  color: #fff;
  text-shadow: 0 1px 2px #000, 0 1px 7px #00000080;
  font-size: 1.5em;
  font-weight: bold;
}

.notification {
  background-color: #ffffff80;
  margin-bottom: 1em;
}

.pic {
  box-shadow: 0 4px 8px #00000080;
}

.map {
  position: relative;
}

.map:after {
  color: #666c84;
  content: "Chaska, MN";
  text-align: center;
  text-shadow: 0 1px 2px #fff;
  text-transform: uppercase;
  z-index: 1;
  font-family: Amiri, sans-serif;
  font-size: 1em;
  font-weight: bold;
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
}

#book-modal .modal-card-body {
  flex-direction: column;
  height: 80vh;
  padding: 0;
  display: flex;
  overflow: hidden;
}

.book-banner > h4 {
  font-size: 1.2em;
  font-weight: bold;
}

.book-banner {
  background: #ffd374;
  border: 1px solid #e6b345;
  border-radius: 2px;
  margin: 10px 20px;
  padding: 10px 20px;
}

#book-frame {
  width: 100%;
  height: 100%;
}

#about p {
  margin-bottom: .5rem;
}

.bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

@media only screen and (device-width >= 320px) and (device-width <= 480px) and (-webkit-device-pixel-ratio >= 2) {
  .bg {
    background-attachment: scroll;
  }
}

#home {
  background-image: url("chair.e851e0aa.jpg");
}

.bg-hair1 {
  background-image: url("hair2.b655d699.jpg");
  height: 300px;
}

.bg-scissors {
  background-image: url("license.881bc553.jpg");
  padding: 8em 0;
}

.bg-scissors h2 {
  color: #fff;
  text-shadow: 0 1px 2px #000;
}

/*# sourceMappingURL=index.0b2b1823.css.map */
